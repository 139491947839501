import"./profil.scss"
import {motion} from"framer-motion"

const textVariants={
  initial:{
    x:-500,
    opacity:0,
  },
  
    animate:{
      x:0,
      opacity:1,
      transition:{
        duration:1,
        staggerChildren:0.1,
     
      }
    }
}
const sliderVariants={
  initial:{
    x:0,
  },
  
    animate:{
      x:"-220%",
      transition:{
        duration:20,
        staggerChildren:0.1,
        repeatType:"mirror",
        repeat:Infinity,
     
      },
    }
}

const Profil = () => {
  return (
    <div className="profil">

 <motion.div className="pozitionare"
         initial={{opacity:0,scale:0.5}} 
         animate={{opacity:1,scale:1}} 
         transition={{duration:1}} >
        <motion.div className="textContainer" variants={textVariants} initial="initial" animate="animate">
        <motion.h2  variants={textVariants}>Dan Boldizsar</motion.h2>
        <motion.h1  variants={textVariants}>REALIZATOR ȘI PRODUCĂTOR DE FILM</motion.h1>
        </motion.div>
</motion.div>
<motion.div variants={sliderVariants} initial="initial" animate="animate" className="slidingText">PRODUCĂTOR DE FILM</motion.div>
        <div className="imageContainer">
            <img variants={textVariants} className="border"src="/Profil1.jpeg" alt="Profile"/>
        </div>
    </div>
  )
}
export default Profil;

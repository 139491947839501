import "./contact.scss"
import {motion} from"framer-motion"
import emailjs from '@emailjs/browser';
import { useRef, useState } from "react";

const variants={
    initial:{
        y:500,
        opacity:0
    },
    animate:{
    y:0,
    opacity:1,
    transition:{
        duration:1,
        staggerChildren:0.1,

    }
    }
}
const Contact = () => {
  const form = useRef();
  const[error,setError]=useState(false);
  const[success,setSuccess]=useState(false);


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_o8qoy3m', 'template_ak9hjrk', form.current, {
        publicKey: 'jeokjibz1fnYXk7yV',
      })
      .then(
        (result) => {
        setSuccess(true)

        },
        (error) => {
         setError(true)
        },
      );
  };


  return (
    <motion.div className="contact" variants={variants} initial="initial" whileInView="animate">
      <motion.div className="textContainer" variants={variants}>
    <motion.h1 variants={variants}>Lasă o părere pe email</motion.h1>
    <motion.div className="item"variants={variants}>
    <h2><img className="logo" src="./youtube.png"alt="Email"/><br/>Youtube Chanel: <span><h6 className="text3">www.youtube.com/@montanavlog</h6></span></h2>
   
    </motion.div>
    <motion.div className="item"variants={variants}>
    <h2><img className="logo" src="./instagram.png"alt="Map"/><br/>Instagram: <span><h6 className="text1">www.instagram.com/montanaromaniaveteran/</h6></span></h2>
    </motion.div>
      </motion.div>
      <motion.div className="formContainer" variants={variants}>
        <motion.div className="phoneSvg" initial={{opacity:1}} whileInView={{opacity:0}}
        transition={{delay:3,duration:1}}>
        <svg className="imagesvg" width="800px" height="800px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" fill="none"  stroke="orange"><motion.path initial={{pathLentgh:0}} d="M49 15a24 24 0 0 1 0 34"/><path d="M42 22a14.15 14.15 0 0 1 0 20"/><rect x="8" y="8" width="28" height="48" rx="4"/><line x1="18" y1="12" x2="26" y2="12"/><line x1="20" y1="52" x2="24" y2="52"/></svg>
        </motion.div>
        <motion.form 
        ref={form}
        onSubmit={sendEmail}
        initial={{opacity:0}} whileInView={{opacity:1}}
        transition={{delay:4,duration:1}}>
           <input type="text" required placeholder="Name" name="name"/> 
           <input type="email" required placeholder="Email" name="email"/>
           <textarea rows={8} placeholder="Message" name="message"/>
           <button>Trimitel Către Mine</button>
           {error && "Error"}
           {success &&"Success"}
        </motion.form>
        </motion.div>

    </motion.div>
  )
}

export default Contact

import Sidebar from "../sidebar/Sidebar"
import "./navbar.scss"
import {motion} from"framer-motion"


const Navbar = ()=>{
    return(
    <div className="navbar">
        <Sidebar/>   
        <div className="wrapper">
            <motion.span initial={{opacity:0,scale:0.5}} 
                         animate={{opacity:1,scale:1}} 
                         transition={{duration:1}} >Montana Vlog</motion.span>
            <div className="links">
                <a href="https://www.tipeeestream.com/supportmontana/donation"><img src="/Sustinere.jpg"alt="youtube"/><p>Sustine-mă</p></a>
                <a href="https://www.youtube.com/@montanavlog"><img src="/youtube.png"alt="youtube"/></a>
                <a href="https://www.instagram.com/montanaromaniaveteran/"><img src="/instagram.png"alt="instagram"/></a>
                <a href="https://www.tiktok.com/@montana.oficial"><img src="/tiktok.png"alt="instagram"/></a>
            </div>
        </div>
    </div>
    
    )
}

export default Navbar
import { useRef, useState } from "react";
import "./portofoliu.scss";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";

const items = [
  {
    id: 1,
    title: "Filmul Cicatrici",
    video: ["./Cicatrici Trailer Oficial 2024.mp4"],
    type: "video/mp4",
    desc: "Veteran Film Production prezinta CICATRICI ! In curand in cinema !",
  },
  {
    id: 2,
    title: "Film Serial Recidivist",
    video: ["./MONTANA - RECIDIVIST ( OST ).mp4"],
    desc: "Recidivist este un film serial realizat din suflet care se gaseste integral pe canalul me de youtube.",
  },
  {
    id: 3,
    title: "Youtube Vlog",
    video: ["./Montana vlog.mp4"],
    desc: "Acesta este canalul meu de youtube unde poți găsi sfaturi și trăiri interesante.",
  },
];

const Single = ({ item }) => {
  const ref = useRef();
  const [activeDot, setActiveDot] = useState(0);

  const { scrollYProgress } = useScroll({ target: ref });
  const y = useTransform(scrollYProgress, [0, 1], [-300, 300]);


  return (
    <section>
      <div className="container">
        <div className="pozitionare">
          <div className="imageContainer" ref={ref}>
            {Array.isArray(item.video) &&
              item.video.map((video, index) => (
                <video
                  key={index}
                  src={video}
                  type={item.type}
                  autoPlay
                  controls
                  alt={`Project ${item.id} video ${index + 1}`}
                  style={{ display: activeDot === index ? "block" : "none" }}
                />
              ))}
            <p />
           
          </div>

          <motion.div style={{ y }} className="textContainer">
            <h2>{item.title}</h2>
            <p>{item.desc}</p>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

const Portofoliu = () => {
  const ref = useRef();

  const { scrollYProgress } = useScroll({ target: ref, offset: ["end end", "start start"] });

  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
  });

  return (
    <div className="portofoliu" ref={ref}>
      <div className="progres">
        <h1>REALIZĂRILE MELE</h1>
        <motion.div style={{ scaleX }} className="progresBar"></motion.div>
      </div>
      {items.map((item) => (
        <Single item={item} key={item.id} />
      ))}
    </div>
  );
};

export default Portofoliu;

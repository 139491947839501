import { useState } from "react"
import Button from "./Button/Button"
import "./sidebar.scss"
import {motion} from"framer-motion"
import Links from "./links/Links"



const variants={
  open:{
    clipPath:"circle(1600px at 50px 50px)",
    transition:{
      type:"spring",
      stiffness:20,
    },

  },
  closed:{
    clipPath:"circle(30px at 50px 50px)",
    transition:{
      display:0.5,
      type:"spring",
      stiffness:400,
      damping:40,

    }
  }
}

const Sidebar = () => {

  const[open,setOpen]=useState(false)


  return (
    <motion.div className="sidebar" animate={open?"open":"closed"}>
      <motion.div className="bg" variants={variants}>
      <Button setOpen={setOpen}/>
        <Links/>
      </motion.div>
    </motion.div>
  )
}

export default Sidebar

import"./cicatrici.scss"

const Cicatrici = () => {
  return (
    <div className="cicatrici" >
        
        <div className="fullscreen">
            <h1> CICATRICI ÎN CURAND ÎN CINEMA 🍿 </h1>
        </div>


    </div>
  )
}
export default Cicatrici;
import {motion} from"framer-motion"

const linkVariants={
    open:{
        transition:{
            staggerChild:0.1,
        },
    },
    closed:{
        transition:{
            staggerChild:0.05,
            staggerDirection:-1,
        },
    },
};
const itemVariants={
    open:{
        y:0,
        opacity:1,
    },
    closed:{
        y:50,
        opacity:0,
    },
};

 const Links = () => {const items=["Homepage","Portfolio","Contact"];
  return(<motion.div className="links" variants={linkVariants} >{items.map(item=>(<motion.a href={`#${item}`} key={item} variants={itemVariants} whileHover={{scale:1.1}} whileTap={{scale:0.95}}>{item}</motion.a>))}</motion.div>);};

export default Links;